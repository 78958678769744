import { Link } from "react-router-dom";

const AdmHome = () =>{
    return(<>
        <div className="row">
            <div className="col s12 m3">
                 <div className="card">
                    <div className="card-content">
                        <div className="row">
                            <div className="col s12">
                                  <h5>POD</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-action">
                        <Link to="/status/pod/upload">UPLOAD</Link>
                    </div>
                 </div>
            </div>

            <div className="col s12 m3">
                 <div className="card">
                    <div className="card-content">
                        <div className="row">
                            <div className="col s12">
                                  <h5>INCOMING</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-action">
                        <Link to="/io/n/isave">ADD</Link>
                        <Link to="/io/in/list">VIEW</Link>
                    </div>
                 </div>
            </div>

            <div className="col s12 m3">
                 <div className="card">
                    <div className="card-content">
                        <div className="row">
                            <div className="col s12">
                                  <h5>OUTGOING</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-action">
                        <Link to="/io/n/osave">ADD</Link>
                        <Link to="/io/out/list">VIEW</Link>
                    </div>
                 </div>
            </div>

            <div className="col s12 m3">
                 <div className="card">
                    <div className="card-content">
                        <div className="row">
                            <div className="col s12">
                                  <h5>STATUS</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-action">
                        <Link to="/">ADD</Link>
                        <Link to="/">VIEW</Link>
                    </div>
                 </div>
            </div>
        </div>
    </>);
}

export default AdmHome;