import { useEffect } from "react";
import { useState } from "react"
import { getConsignmentList } from "../../db/fs.coureer";
import { getStrDate } from "../../utils/dates";

const List_Of_Incoming = () =>{
    const[list, setList] = useState([]);

    const loadData = async () =>{
        const _list = await getConsignmentList(); console.log(_list);
        setList(_list);
    }

    useEffect(()=>{
        loadData();
    }, []);


    return(<>
        <div className="row">
            <div className="col s12">
                <h5>INCOMING</h5>
            </div>
            
            <div className="col s12">
                <div style={{width:"100%", minHeight:"80dvh", height:"80dvh", overflowY:"scroll"}}>
                <table className="striped">
                    <thead>
                        <tr>
                            <td>CN. NO</td>
                            <td>DATE</td>
                            <td>ORIGIN</td>
                            <td>DESTINATION</td>
                            <td>HUB</td>
                            <td>STATUS</td>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(item =><tr key={item.id}>
                            <td style={{padding:"5px 5px"}}>{item.cnNo}</td>
                            <td style={{padding:"5px 5px"}}>{getStrDate(item.longDate)}</td>
                            <td style={{padding:"5px 5px"}}>{item.orgCity}</td>
                            <td style={{padding:"5px 5px"}}>{item.destCity}</td>
                            <td style={{padding:"5px 5px"}}>{item.hubCity}</td>
                            <td style={{padding:"5px 5px"}}>{item.status}</td>
                        </tr>)}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    </>)
}
export default List_Of_Incoming