export const coureer = {
    cnNo            : "",
    isOut           : 0,
    despatchType    : "",
    docType         : "NON-DOCUMENT",
    qnty            : 1,
    unit            : "KGS",
    pktCount        : 1,

    destCityCode    : "",
    destCity        : "",
    hubCityCode     : "",
    hubCity         : "",

    orgPhone        : "",
    orgName         : "",
    orgCity         : "",
    orgCityCode     : "",
    orgAddress      : "",
    ledgerName      : "",

    destPhone       : "",
    destName        : "",
    destAddr        : "",
    
    localId          : 0,

    manifestNo       : 0,
    manifestDateLong : 0,
    manifestDateInt  : 0,
    manifestDateStr  : 0,
    podLink          : "",
    currentStatus    : "",

    longDate:0,
    intDate :0,
    strDate :"",

    createOn:0,
    createBy:"",
    modifyOn:0,
    modifyBy:"",
    updateOn:0,
}

export const status = {
    cnNo        : "",
    statusCode  : 0, //0 = registered | 100 = delivered
    cnStatus    : "",
    deliveredBy : "",
    localId     : 1,
    longDate    : 0,
    particulars : "",
    podLink     : "",

    createOn    :0,
    createBy    :"",
    modifyOn    :0,
    modifyBy    :"",
    updateOn    :0,
}