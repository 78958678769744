import { useEffect } from "react";
import { useState } from "react"
import { getConsignmentList } from "../../db/fs.coureer";

const List_Of_Outgoing = () =>{
    const[list, setList] = useState([]);

    const loadData = async () =>{
        const _list = await getConsignmentList(); console.log(_list);
        setList(_list);
    }

    useEffect(()=>{
        loadData();
    }, []);
    return(<>
        <div className="row">
            <div className="col s12">
                <h5>OUTGOING</h5>
            </div>
        </div>
    </>)
}
export default List_Of_Outgoing