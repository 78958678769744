import { add2Fs, fsCollection, set2Fs, updateFs } from "../fs/fire"

export const createConsignment = async (cons) =>{
      cons.createOn = Date.now();
      cons.modifyOn = Date.now();
      cons.updateOn = Date.now();
      return await add2Fs(fsCollection.COUREER_IO_MASTER, cons);
}

export const saveConsignment = async (id, cons) =>{
      cons.modifyOn = Date.now();
      cons.updateOn = Date.now();
      return await set2Fs(fsCollection.COUREER_IO_MASTER, id, cons);
}

export const updatePodLink = async (id, link) =>{
      return await updateFs(fsCollection.COUREER_IO_MASTER, id, {podLink : link, updateOn : Date.now()});
}

