import {fsdb, toList} from "../fs/fire";

export const getConsignment = async (cnNo) =>{
      const db      = fsdb();
      const snap    = await db.collection("COUREER_IO_MASTER")
                        .where("cnNo", "==", cnNo).get();
      const arr     = toList(snap);
      
      if(arr.length > 0){
          console.log(arr[0]);
          return arr[0];
      }
      
      return {};
}

export const getConsignmentList = async () =>{
      const db      = fsdb();
      const snap    = await db.collection("COUREER_IO_MASTER")
                        .where("updateOn", "<", Date.now()).orderBy("updateOn", "desc").limit(20).get();
      const arr     = toList(snap);
      return arr;
}


export const getStatusList = async (cnNo) =>{
    const arr     = [];
    const db      = fsdb();
    const snap    = await db.collection("COUREER_STATUS")
                        .where("cnNo", "==", cnNo).orderBy("longDate", "asc").get();
      
      snap.forEach(doc => {
          const a = doc.data();
          a.id = doc.id;
          arr.push(a);
      });

      console.log(arr);
      return arr;
}
