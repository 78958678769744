import { useEffect, useRef, useState } from "react";
import { coureer } from "../../db/mdl.coureer";
import M from "materialize-css";
import { createSelect } from "../../utils/select.handler";
import { getConsignment } from "../../db/fs.coureer";
import { citylist } from "../../utils/citylist";
import { getIntDate, getStrDate4Pkr } from "../../utils/dates";
import { createConsignment, saveConsignment } from "../../db/fs.coureer.save";

const IO_Entry = ({id, isOut}) =>{
    const cities                = citylist;
    const[iid, setIid]          = useState(id);
    const[cons, setCons]        = useState(coureer);
    const[master, setMaster]    = useState(coureer);
    const[msg, setMsg]          = useState({isOk : false, text : ""})
    const tf_cnNo               = useRef();
    const tf_destcode           = useRef();
    const tf_destCity           = useRef();
    const tf_hubCode            = useRef();
    const tf_hubCity            = useRef();
    const tf_orgCode            = useRef();
    const tf_orgCity            = useRef();
    const tf_docType            = useRef();
    const tf_pktCount           = useRef();
    const tf_weight             = useRef();
    const tf_weightu            = useRef();
    const btnSave               = useRef();

    const onEntr = async (e) =>{
        if(e.key === "Enter"){
            if(e.target.value === ""){return;}
            const found = await loadConsignment(e.target.value);
            tf_destcode.current.focus();
        }
    }

    const loadConsignment = async (cnNo) =>{
        const _mdl = await getConsignment(cnNo);
        if(Object.keys(_mdl).length > 0){
            const strDate = getStrDate4Pkr(_mdl.longDate);
            const intDate = getIntDate    (_mdl.longDate);
            _mdl.strDate  = strDate;
            _mdl.intDate  = intDate;
            setCons(_mdl);
            setIid (_mdl.id);
            return true;
        }else{
            master.cnNo = cnNo;
            master.strDate  = getStrDate4Pkr(Date.now());
            master.intDate  = getIntDate    (Date.now());
            setIid ("n");
            setCons(master);
            return false;
        }
    }

    const resetFields = () =>{
            master.cnNo     = "";
            master.strDate  = getStrDate4Pkr(Date.now());
            master.intDate  = getIntDate    (Date.now());
            setIid ("n");
            setCons(master);
            tf_cnNo.current.focus();
    }

    const loadDestCity = (c) =>{
        for(let i = 0; i < cities.length; i++){
            if(cities[i].code === c.toUpperCase()){
                setCons({...cons, destCity : cities[i].cName, destCityCode : c.toUpperCase()});
                break;
            }
        }
    }

    const loadHub = (c) =>{
        for(let i = 0; i < cities.length; i++){
            if(cities[i].code === c.toUpperCase()){
                setCons({...cons, hubCity : cities[i].cName, hubCityCode : c.toUpperCase()});
                break;
            }
        }
    }

    const loadOrg = (c) =>{
        for(let i = 0; i < cities.length; i++){
            if(cities[i].code === c.toUpperCase()){
                setCons({...cons, orgCity : cities[i].cName, orgCityCode : c.toUpperCase()});
                break;
            }
        }
    }
    
    const save = async () =>{
        if("id" in cons){delete cons.id;}

        if(cons.cnNo.length < 4){
            setMsg({isOk : false, text : "Consignment number is invalid"});
            return;
        }

        if(cons.destCityCode.length < 3){
            setMsg({isOk : false, text : "Destination code is invalid"});
            return;
        }

        if(cons.destCity.length === 0){
            setMsg({isOk : false, text : "Destination is empty"});
            return;
        }

        if(cons.hubCityCode.length < 3){
            setMsg({isOk : false, text : "Hub is invalid"});
            return;
        }

        if(cons.hubCity.length === 0){
            setMsg({isOk : false, text : "Hub is empty"});
            return;
        }

        if(cons.orgCityCode.length < 3){
            setMsg({isOk : false, text : "City of origin is invalid"});
            return;
        }

        if(cons.orgCity.length === 0){
            setMsg({isOk : false, text : "City of origin is empty"});
            return;
        }

        cons.isOut          = isOut;
        cons.despatchType   = isOut === 0 ? "Outgoing" : "Incoming";
        cons.longDate       = Date.parse(cons.strDate);
        cons.intDate        = getIntDate(cons.longDate);

        console.log(cons);
        if(iid.length > 1){
            console.log( iid + " will be updated");//update
            const __id = await saveConsignment(iid, cons);
            if(__id.length > 0){
                resetFields();
            }
        }else{
            console.log( iid + " will be inserted");//insert
            const __id = await createConsignment(cons);
            if(__id.length > 0){
                resetFields();
            }
        }
    }

    useEffect(()=>{}, []);

    return(<>
        <div className="row">
            <div className="col s12">
                <div className="card">
                    <div className="card-content">
                        <div className="row">
                            <div className="col s12">
                                <h5>{isOut ? "OUTGOING" : "INCOMING"}</h5>
                            </div>

                            <div className="col s12 m3 input-field">
                                <input id="tf_cnNo" ref={tf_cnNo} value={cons.cnNo} 
                                    onChange={e=>setCons({...cons, cnNo : e.target.value})} 
                                    onBlur={e=>loadConsignment(e.target.value)}
                                    onKeyDown={e=>onEntr(e)}
                                    />
                                <label htmlFor="tf_cnNo" className="active">Consignment No.</label>
                            </div>

                            <div className="col s12 m3 input-field">
                                <input id="tf_cnDate" type="date" value={cons.strDate} onChange={e=>setCons({...cons, strDate : e.target.value})}/>
                                <label htmlFor="tf_cnDate" className="active">Date</label>
                            </div>

                            <div className="col s12 m3 input-field">
                                <input id="tf_destCode" 
                                    ref={tf_destcode} 
                                    value={cons.destCityCode} 
                                    onBlur={e=>loadDestCity(e.target.value)}
                                    onKeyDown={e=>{if(e.key==="Enter"){tf_destCity.current.focus()}}}
                                    onChange={e=>setCons({...cons, destCityCode : e.target.value})} />
                                <label htmlFor="tf_destCode" className="active">Destination Code</label>
                            </div>

                            <div className="col s12 m3 input-field">
                                <input id="tf_destCity" 
                                    ref={tf_destCity}
                                    value={cons.destCity} 
                                    onKeyDown={e=>{if(e.key==="Enter"){tf_hubCode.current.focus()}}}
                                    onChange={e=>setCons({...cons, destCity : e.target.value})} />
                                <label htmlFor="tf_destCity" className="active">Destination City</label>
                            </div>

                            <div className="col s12 m3 input-field">
                                <input id="tf_hubCode" 
                                    value={cons.hubCityCode} 
                                    ref={tf_hubCode}
                                    onBlur={e=>loadHub(e.target.value)}
                                    onKeyDown={e=>{if(e.key==="Enter"){tf_hubCity.current.focus()}}}
                                    onChange={e=>setCons({...cons, hubCityCode : e.target.value})} />
                                <label htmlFor="tf_hubCode" className="active">Hub Code</label>
                            </div>

                            <div className="col s12 m3 input-field">
                                <input id="tf_hubCity" 
                                    ref={tf_hubCity}
                                    value={cons.hubCity} 
                                    onKeyDown={e=>{if(e.key==="Enter"){tf_orgCode.current.focus()}}}
                                    onChange={e=>setCons({...cons, hubCity : e.target.value})}/>
                                <label htmlFor="tf_hubCity" className="active">Hub City</label>
                            </div>

                            <div className="col s12 m3 input-field">
                                <input id="tf_orgCode" 
                                    ref={tf_orgCode}
                                    value={cons.orgCityCode} 
                                    onBlur={e=>loadOrg(e.target.value)}
                                    onKeyDown={e=>{if(e.key==="Enter"){tf_orgCity.current.focus()}}}
                                    onChange={e=>setCons({...cons, orgCityCode : e.target.value})} />
                                <label htmlFor="tf_orgCode" className="active">Origin City Code</label>
                            </div>

                            <div className="col s12 m3 input-field">
                                <input id="tf_orgCity" 
                                ref={tf_orgCity}
                                value={cons.orgCity} 
                                onKeyDown={e=>{if(e.key==="Enter"){tf_docType.current.focus()}}}
                                onChange={e=>setCons({...cons, orgCity : e.target.value})}/>
                                <label htmlFor="tf_orgCity" className="active">Origin City</label>
                            </div>
                            
                            <div className="col s12 m3 input-field">
                                <input id="tf_type" 
                                    ref={tf_docType}
                                    value={cons.docType} 
                                    onKeyDown={e=>{
                                        if(e.key === "n" || e.key === "N"){
                                            setCons({...cons, docType : "NON-DOCUMENT"})
                                        }
                                        
                                        if(e.key === "d" || e.key === "D"){
                                            setCons({...cons, docType : "DOCUMENT"})
                                        }

                                        if(e.key === "Enter"){
                                            tf_pktCount.current.focus();
                                        }
                                    }}
                                    />
                                <label htmlFor="tf_type" className="active">Doc Type</label>
                            </div>
                            
                            <div className="col s12 m3 input-field">
                                <input id="tf_count" 
                                    ref={tf_pktCount}
                                    value={cons.pktCount} 
                                    onKeyDown={e=>{if(e.key === "Enter"){tf_weight.current.focus()}}}
                                    onChange={e=>setCons({...cons, pktCount : parseInt(e.target.value | 0)})} />
                                <label htmlFor="tf_count" className="active">No. of Pkts</label>
                            </div>
                            
                            <div className="col s12 m3 input-field">
                                <input id="tf_weight" 
                                    ref={tf_weight}
                                    onKeyDown={e=>{if(e.key === "Enter"){tf_weightu.current.focus()}}}
                                    value={cons.qnty} onChange={e=>setCons({...cons, qnty : parseFloat(e.target.value | 0)})} />
                                <label htmlFor="tf_weight" className="active">Weight</label>
                            </div>
                            
                            <div className="col s12 m3 input-field">
                                <input id="tf_weightu" 
                                    ref={tf_weightu}
                                    onKeyDown={e=>{
                                        if(e.key === "k" || e.key === "K"){setCons({...cons, unit : "KGS"})}
                                        if(e.key === "g" || e.key === "G"){setCons({...cons, unit : "GMS"})}
                                        if(e.key === "Enter"){btnSave.current.focus()}
                                    }}
                                    value={cons.unit} />
                                <label htmlFor="tf_weightu" className="active">Unit</label>
                            </div>

                            <div className="col s12">
                                {msg.text && 
                                    <div className={msg.isOk ? "green lighten-4" : "pink lighten-5"} 
                                        style={{
                                            marginTop:"1em", marginBottom:"1em", padding:"0.5em", 
                                            borderRadius:"10px", border: msg.isOk ? "1px solid green" : "1px solid red"
                                            }}>
                                        <p style={{fontWeight:"600"}}>{msg.text}</p>
                                    </div>
                                }
                            </div>
                            <div className="col s12">
                                <button ref={btnSave} className="btn btn-flat green white-text" onClick={e=>save()}>SAVE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}


export default IO_Entry;