export const citylist = [
      {code : "GAU", cName : "GUWAHATI"},
      {code : "TSK", cName : "TINSUKIA"},
      {code : "JRT", cName : "JORHAT"},
      {code : "DBR", cName : "DIBRUGARH"},
      {code : "GLD", cName : "GOLAGHAT"},
      {code : "SBS", cName : "SIBSAGAR"},
      {code : "NLP", cName : "NORTH LAKHIMPUR"},
      {code : "DHUBRI", cName : "DHUBRI"},
      {code : "TEZ", cName : "TEZPUR"},
      {code : "NOW", cName : "NOWGAON"},
      {code : "DMP", cName : "DIMAPUR"},
      {code : "SHG", cName : "SHILLONG"},
      {code : "IMF", cName : "IMPHAL"},
      {code : "NLB", cName : "NALBARI"},
      {code : "KXJ", cName : "KARIMGANJ"},
      {code : "HOJAI", cName : "HOJAI"},
      {code : "DIPHU", cName : "DIPHU"},
      {code : "LMD", cName : "LUMDING"},
      {code : "BDN", cName : "BANDERDEWA"},
      {code : "BNG", cName : "BONGAIGAON"},
      {code : "PTS", cName : "PATHSHALA"},
      {code : "TURA", cName : "TURA"},
      {code : "AIZ", cName : "AIZWAL"},
      {code : "SIL", cName : "SILCHAR"},
      {code : "BPT", cName : "BARPETA"},
      {code : "GLP", cName : "GOLPARA"},
      {code : "KPT", cName : "KHARUPETIA"},
      {code : "DEL", cName : "DELHI"},
      {code : "AGT", cName : "AGARTALLA"},
      {code : "DMR", cName : "DHARAMNAGAR"},
      {code : "KOL", cName : "KOLKATA"},
      {code : "MAA", cName : "MADRAS"},
      {code : "HYD", cName : "HYDERABAD"},
      {code : "MUM", cName : "MUMBAI"},
      {code : "BLR", cName : "BANGLORE"},
      {code : "LUDHIANA", cName : "LUDHIANA"},
      {code : "SLG", cName : "SILIGURI"},
      {code : "PUNE", cName : "PUNE"},
      {code : "NLG", cName : "NAHARLAGUN"},
      {code : "ITA", cName : "ITANAGAR"},
];